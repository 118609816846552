.snackbar-actions {
  &-dismiss {
    color: white !important;
  }

  &-pending-transaction-container {
    display: flex;
    align-items: center;
  }

  &-explorer-link {
    margin-left: 8px;
    text-transform: uppercase;
    color: white;
  }

  &-spinner {
    color: white !important;
  }
}
