@import '../../assets/styles/colors';

.ccb {
  max-width: 520px;
  margin: 0 auto;
  margin-top: 96px;

  &-balance-label {
    margin-bottom: 12px;
  }

  &-balance-value {
    display: block;
    font-size: 24px;
    line-height: 26px;
  }

  &-main-text {
    font-size: 16px;
    line-height: 19px;
    color: $greyFirst;
  }

  &-networks-transfer {
    display: block;
    color: $greyThird;
    font-weight: 500;
    margin: 14px 0;
    width: 220px;
  }

  &-secondary-text {
    font-size: 14px;
    line-height: 17px;
    color: $greyFirst;
  }

  &-input-container {
    margin: 6px 0px 24px 0px;
  }

  &-input {
    height: 47px;
  }

  &-currency {
    color: $greySecond;
  }

  &-receive-token {
    margin-top: 12px;
  }

  &-divider {
    &.MuiDivider-root {
      margin: 10px 0px;
    }
  }

  &-submit-buttons {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
  }

  &-spacer {
    display: flex;
    justify-content: center;
    width: 52px;
  }

  &-unlimited-approval-info {
    color: $error;
    white-space: pre-wrap;
    line-height: 20px;
  }
}
