@import '../../assets/styles/colors';

.mobile {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-image {
      width: 68px;
    }

    &-title {
      font-family: 'Futura PT', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 46px;
      text-align: center;
      color: $text;
      margin: 26px 0;
    }

    &-subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: $greyThird;
      margin-bottom: 52px;
    }

    &-download {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      div {
        &:nth-child(1) {
          margin-right: 25px;
        }
      }

      a {
        width: 152px;

        img {
          border-radius: 10px;
        }
      }
    }
  }
}
