@import '../../assets/styles/colors';

.contract-icon-rounded {
  &-bnb-wrapper {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 6px;
    box-shadow: 0 0 16px $tileShadow;
  }
}
