@import '../../assets/styles/colors';

.common-button {
  &-root {
    &.MuiButton-root {
      width: 100%;
      border-radius: 8px;
      padding: 8px 44px;
      background: $primary;
      box-sizing: border-box;
    }
    &.MuiButton-root:hover {
      background: $primary;
    }
    &.MuiButton-root:disabled {
      background: $greySeventy;
      .common-button-label {
        color: $greyFirst;
      }
    }
    &.MuiButton-outlined {
      border-width: 1px;
      border-color: $primary;
      background: transparent;
    }
    &.MuiButton-outlined:disabled {
      border-width: 1px;
      border-color: $primary;
      background: transparent;
    }
    &.MuiButton-outlined:hover {
      background: transparent;
    }
  }
  &-full-width {
    display: flex;
    flex: 1;
  }
  &-label {
    color: $white;
    text-transform: none;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    &-negative {
      color: $primary;
    }
  }
  &-large {
    &.MuiButton-root {
      padding: 12px 44px;
    }
  }
  &-small {
    &.MuiButton-root {
      padding: 0.375rem 0.5rem;
    }
  }
  &-label-large {
    font-size: 20px;
    line-height: 24px;
  }
  &-label-small {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  &-label-greyish-negative {
    color: $greyishButton;
  }
  &-secondary {
    &.MuiButton-root {
      background: $secondary;
    }
    &.MuiButton-root:hover {
      background-color: $secondary;
    }
    &.MuiButton-root:disabled {
      opacity: 0.7;
    }
  }
  &-greyish {
    &.MuiButton-root {
      background-color: $greyishButton;
    }
    &.MuiButton-root:hover {
      background-color: $greyishButton;
    }
    &.MuiButton-root:disabled {
      background: $greyishButton;
      opacity: 0.7;
    }
  }
  &-greyish-negative {
    &.MuiButton-root {
      border: 1px solid $greyishButton;
      background: transparent;
    }
    &.MuiButton-root:disabled {
      background: transparent;
      opacity: 0.7;
    }
    &.MuiButton-contained:hover {
      background: transparent;
    }
  }
  &-spinner {
    &.MuiCircularProgress-colorPrimary {
      color: $white;
      margin-right: 10px;
    }
    &-negative {
      &.MuiCircularProgress-colorPrimary {
        color: $primary;
      }
    }
  }
}
