@import '../../../../assets/styles/colors';

.individual-tokens {
  &-main-text {
    font-size: 16px;
    line-height: 20px;
  }
  &-title {
    font-size: 18px;
    line-height: 26px;
  }
  &-wallet-icon {
    width: 64px;
    height: 64px;
  }
  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 24px;
  }
  &-empty-content {
    align-items: center;
    justify-content: center;
  }
  &-disconnected-wallet-label {
    display: block;
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: center;
  }
  &-item-wrapper {
    display: flex;
    align-items: flex-start;
  }
  &-item-content {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
  }
  &-secondary-text {
    font-size: 14px;
    line-height: 18px;
  }
  &-balance-value {
    margin-top: 2px;
    margin-bottom: 8px;
  }
  &-balance-label {
    opacity: 0.5;
  }
  &-metamask-wrapper {
    display: flex;
    align-items: center;
  }
  &-add-to-metamask {
    margin-left: 8px;
    color: $primary;
    cursor: pointer;
  }
  &-divider {
    margin: 18px 0px;
  }
}
