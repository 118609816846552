@import '../../../../assets/styles/colors';

.full-name-and-phone {
  &-input {
    margin-bottom: 40px;
  }
  &-input-phone {
    margin-bottom: 26px;
  }
  &-next-button {
    margin-top: 40px;
  }
  &-terms-text {
    font-size: 14px;
    color: $validationInfo;
  }
  &-link {
    color: $primary;
  }
}
