.version-page {
  &-root {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1,
    h3 {
      padding: 0 20px;
    }
  }
}
