@import '../../assets/styles/colors';

.form-text-filed {
  &-wrapper {
    display: flex;
    flex: 1;
    position: relative;
  }
  &-root {
    width: 100%;
    div {
      &.MuiFilledInput-root {
        background-color: $input;
        border-radius: 8px;
        border-bottom-color: transparent;
        &.Mui-error {
          border: 1px solid $error;
          box-sizing: border-box;
          input {
            color: $error;
          }
        }
        &.Mui-error:after {
          border-bottom-color: transparent;
        }
        input {
          font-family: 'Futura PT', sans-serif;
          padding: 24px 20px 6px;
          border-radius: 8px;
        }
      }
      &.MuiFilledInput-root::before {
        border-bottom-width: 0;
      }
      &.MuiFilledInput-root::after {
        border-bottom-width: 0;
      }
      &.MuiFilledInput-root:hover::before {
        border-bottom-width: 0;
      }
    }
    label {
      &.MuiInputLabel-root {
        font-family: 'Futura PT', sans-serif;
        color: $inputLabel;
        margin-left: 8px;
      }
      &.Mui-error {
        color: $error;
      }
    }
  }

  &-placeholder-shown {
    div {
      &.MuiFilledInput-root {
        display: flex;
        flex: 1;
        input {
          padding: 0 20px;
        }
      }
    }
  }

  &-focused {
    .Mui-focused {
      outline: 1px solid $greyFirst !important;
      background-color: transparent !important;
    }
  }

  &-error-text {
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
    bottom: calc(-0.75rem * 1.2);
    color: $error;
    font-size: 0.75rem;
    margin-top: 0;
    line-height: 12px;
    &.MuiFormHelperText-root {
      margin: 3px 0px -8px;
    }
  }

  &-right-component-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    background-color: $input;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 16px;
    &-error {
      border: 1px solid $error;
      border-left-width: 0;
    }
  }

  &-right-component-exist {
    div {
      &.MuiFilledInput-root {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.Mui-error {
          border-right-width: 0;
        }
      }
    }
  }
}
