@import '../../assets/styles/colors';

.typography {
  &_display {
    &_initial {
      display: initial;
    }

    &_block {
      display: block;
    }

    &_inline {
      display: inline;
    }
  }

  &_variant {
    &_inherit {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    &_body {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &_bodySmall {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    &_h1 {
      font-weight: 500;
      font-size: 2.5rem;
      line-height: 3.25rem;
    }

    &_h2 {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    &_h3 {
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 1.75rem;
    }

    &_h4 {
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
  }

  &_color {
    &_textPrimary {
      color: $greyTwenty;
    }

    &_textSecondary {
      color: $greyTen;
    }

    &_initial {
      color: initial;
    }

    &_inherit {
      color: inherit;
    }
  }

  &_align {
    &_left {
      text-align: left;
    }

    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }

    &_justify {
      text-align: justify;
    }
  }

  &_font {
    &_futura {
      font-family: 'Futura PT', sans-serif;
    }
  }

  &_weight {
    &_400 {
      font-weight: 400;
    }
    &_500 {
      font-weight: 500;
    }
    &_600 {
      font-weight: 600;
    }
  }

  &_uppercase {
    text-transform: uppercase;
  }

  &_nowrap {
    white-space: nowrap;
  }
}
