@import '../../../../assets/styles/colors';

.terms {
  &-description-text {
    color: $greyTen;
    font-size: 16px;
  }
  &-block {
    margin: 32px 0;
    &:last-child{
      margin: 32px 0 0 0;
    }
    &-title {
      font-size: 22px;
    }
    &-number {
      color: $primary;
      margin-right: 5px;
      display: inline-block;
      font-size: 22px;
    }
    &-description {
      margin-top: 12px;
      & > span {
        color: $greyTen;
      }
    }
  }
}
