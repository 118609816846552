@import '../../assets/styles/colors';

.staking {
  max-width: 520px;
  margin: 0 auto;
  margin-top: 96px;
  &-row {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
  &-row-centered {
    align-items: center;
  }
  &-title {
    display: block;
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  &-main-text {
    font-size: 16px;
    line-height: 19px;
    color: $greySecond;
  }
  &-secondary-text {
    font-size: 14px;
    line-height: 16px;
    color: $greyFirst;
  }
  &-amount-label {
    margin-bottom: 4px;
  }
  &-spacer {
    width: 12px;
  }
  &-term-buttons {
    margin-top: 22px;
    margin-bottom: 6px;
  }
  &-bullet-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    border-radius: 10px;
    border: 1px solid $greyFirst;
  }
  &-bullet {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: $primary;
  }
  &-bullet-selected {
    border-color: $primary;
  }
  &-checkbox-button {
    &.MuiButton-root {
      background: $greyFourth;
      border: 2px solid $greyFourth;
      span {
        display: flex;
        justify-content: flex-start;
      }
    }
    &.MuiButton-root:hover {
      background: $greyFourth;
    }
    &.MuiButton-root:disabled {
      background: $greyFourth;
    }
    &.MuiButton-label {
      display: block;
      margin-top: 50px;
    }
  }
  &-checkbox-button-selected {
    &.MuiButton-root {
      border: 2px solid $primary;
    }
  }
  &-checkbox-button-label {
    font-size: 16px;
    font-weight: 400;
    color: $greyFifth;
  }
  &-term-redeem {
    display: flex;
    flex: 1;
  }
  &-divider {
    &.MuiDivider-root {
      margin: 16px 0px;
    }
  }
  &-submit-buttons {
    margin-top: 32px;
    justify-content: flex-end;
  }
  &-current {
    max-width: 520px;
    margin: 32px auto 0px auto;
  }
  &-use-max-button {
    &.MuiButton-root {
      @media all and (max-width: 1049px) {
        padding: 8px 0px;
      }
    }
  }
  &-balance-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  &-available-balance {
    font-size: 22px;
    line-height: 26px;
  }
  &-unlimited-approval-info {
    color: $error;
    white-space: pre-wrap;
    line-height: 20px;
  }
}
