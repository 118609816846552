@import '../../../../assets/styles/colors';

.connection-wallet-modal {
  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-main-text {
    font-size: 16px;
    color: $text;
  }

  &-wallet-icon-rounded {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 18px;
    justify-content: center;
    align-items: center;
    background-color: $black;
  }

  &-address {
    font-size: 20px;
    line-height: 26px;
    color: $text;
    margin-left: 12px;
  }

  &-connected-with {
    color: $greySixth;
    margin-right: 10px;
  }

  &-wallet {
    margin-left: 6px;
    margin-right: 26px;
  }

  &-wallet-address-container {
    margin-top: 38px;
    margin-bottom: 20px;
  }

  &-underline-text {
    font-size: 14px;
    color: $primary;
    text-decoration: underline;
  }

  &-icon-rounded {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $white;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 2px $tileShadow;
  }

  &-etherscan-ref {
    margin-left: 12px;
    :active {
      color: $primary;
    }
  }

  &-clipboard-button {
    display: flex;
    background-color: transparent;
    border: 0;
    margin: 0 72px 0 12px;
    cursor: pointer;
  }
}
