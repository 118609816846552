@import '../../../../assets/styles/colors';

.spot-account-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  &-title {
    font-size: 22px;
    line-height: 28px;
    color: $text;
    font-weight: 600;
  }

  &-filter-item {
    display: flex;
    align-items: center;

    &-title {
      margin-right: 4px;
    }
  }

  &-filter-menu {
    &-item {
      &-icon {
        margin-right: 12px;
      }
    }
  }

  &-filter-button-inner {
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      line-height: 20px;
      text-transform: none;
      white-space: nowrap;
    }

    span:last-of-type {
      margin-right: 6px;
      font-weight: 600;
      color: $primary;
    }

    path {
      stroke: $primary;
    }
  }
}
