$primary: #CF46B9;
$primaryLight: #d169df;
$white: white;
$secondary: #F2AB3C;
$input: #f1f2f4;
$error: red;
$inputLabel: #c2c2c2;
$text: #000000;
$green: #38c17c;
$red: #dd4141;
$validationInfo: #676666;
$greyBorder: #e0e0e0;
$greyText: #bdbdbd;
$tileShadow: rgba(0, 0, 0, 0.06);
$greyishButton: #313131;
$bankAccountRounded: rgba(43, 185, 66, 0.15);
$greyBackground: #eef0f4;
$greyFirst: #b6b5c2;
$greySecond: #6c6c6c;
$greyThird: #797979;
$greyFourth: #f1f2f4;
$greyFifth: #373737;
$greySixth: #929292;
$greySeventh: rgba(0, 0, 0, 0.08);
$greyThirteen: #dfdeea;
$greyNinth: #1c1c1f;
$greyEight: #e2e2e9;
$greyTen: #83828f;
$greyEight: #e2e2e9;
$greyEleventh: #e6e6eb;
$greyTwelve: #84878d;
$greyThirteen: #d2d7e5;
$greyFourteen: #525159;
$greyFifteen: #828282;
$greyTwenty: #2e2e33;
$greySeventy: #ebebeb;
$pageBackground: #f6f8fa;
$black: black;
$blueone: rgba(94, 140, 226, 1);
