@import '../../../../assets/styles/colors';

.tx-empty-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px 0;

  &-search-icon-rounded {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background-color: $input;
  }

  &-search-icon {
    font-size: 28px;
    color: $inputLabel;
  }

  &-title {
    font-size: 18px;
    line-height: 24px;
    color: $text;
    font-weight: 600;
    margin: 24px 0 12px 0;
  }

  &-info {
    width: 360px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: $greyThird
  }
}
