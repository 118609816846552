@import '../../../../assets/styles/colors';

.sign-up {
  &-input {
    margin-bottom: 40px;
  }
  &-main-text {
    font-size: 14px;
    color: $text;
  }
  &-input-title {
    display: block;
    padding-bottom: 6px;
  }
  &-re-type-password-input {
    margin-bottom: 16px;
  }
  &-next-button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  &-submit-button {
    &.MuiButton-root {
      width: auto;
    }
  }
}
