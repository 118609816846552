@import '../../../../assets/styles/colors';

.networks-bridge {
  display: flex;
  flex-direction: column;

  &-row {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &-item {
    flex: 1;
    height: 47px;
    border-radius: 8px;
    background-color: $greyFourth;
    padding: 0px 12px;
  }

  &-arrows {
    margin: 0 14px;
  }

  &-network-label {
    color: $greySecond;
    margin-left: 10px;
  }

  &-blockchain-label-wrapper {
    display: flex;
    flex: 1;
    text-align: left;
  }

  &-blockchains {
    margin-bottom: 6px;
  }

  &-change-network-button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;

    &-disabled {
      cursor: default;
    }
  }

  &-tooltip-text {
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
  }
}
