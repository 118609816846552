.drawer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15px;

  &-logo {
    margin: 37px 62px 0px 62px;
  }
  &-list {
    &-item {
      height: 42px;
      transition: ease 0.5s;
      opacity: 0.5 !important;

      &:hover {
        transition: ease 0.5s;
        opacity: 1 !important;
        background-color: transparent !important;
      }
      &-icon {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
      &-text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }

  .activeItem {
    opacity: 1 !important;
    background-color: #eef2f7 !important;
  }

  &-burger-button {
    height: 50px;
    width: 50px;
    position: absolute;
    border: 0px;
    background-color: transparent;
    display: none;
    @media all and (max-width: 900px) {
      display: block;
    }
  }
}
