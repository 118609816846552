@import '../../assets/styles/colors';

.phone-number-input {
  border-radius: 8px 8px 0 0 !important;
  & .arrow {
    display: none !important;
  }

  & .selected-flag {
    display: flex;
    justify-content: center;
    padding: 0 !important;
  }

  & .special-label {
    display: none !important;
  }

  & .form-control {
    width: 100% !important;
    border-radius: 8px !important;
    background-color: $input !important;
    border: none !important;
    align-items: center !important;
    height: 51px !important;
    font-size: 16px;
    font-family: 'Futura PT', sans-serif;
    padding: 12px 45px !important;

    &:hover,
    &:focus,
    &:focus-visible {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  &-focusable,
  &-focusable input {
    transition: 0.3s ease !important;
  }

  &-focusable:focus-within .form-control {
    outline: 1px solid $greyFirst !important;
    background-color: transparent !important;
  }

  & .form-control.invalid-number {
    border: solid 1px $error !important;
    color: $error;

    &:focus {
      box-shadow: none !important;
    }
  }

  & .invalid-number-message {
    font-size: 0.75rem !important;
    color: $error !important;
    position: absolute !important;
    bottom: -17px !important;
    left: 0 !important;
    top: auto !important;
    padding: 0 !important;
    background-color: transparent !important;
    font-family: 'Futura PT', sans-serif;
  }

  & .country {
    font-size: 0.875rem !important;
    color: $text !important;
  }

  & .country-name {
    font-size: 0.875rem !important;
    color: $text !important;
  }

  & .dial-code {
    font-size: 0.875rem !important;
    color: $text !important;
  }
}
