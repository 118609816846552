@import '../../assets/styles/colors';

.fiat-payment {
  min-width: 320px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    background-color: $primary;
  }

  &-image {
    width: 96px;
    height: auto;
  }

  &-wrapper {
    max-width: 392px;
    width: 100%;
    align-self: center;
    padding: 0 16px 16px;
    display: flex;
    flex-direction: column;
    flex: 1;

    &-title {
      margin: 24px 0;
      text-align: center;
      color: $text;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: -0.47px;
      line-height: 24px;
    }

    &-label {
      margin-bottom: 8px;
      color: $text;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.37px;
      line-height: 19px;
    }

    &-value {
      margin-bottom: 20px;
      color: #B3A3B3;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.37px;
      line-height: 19px;
    }

    &-download {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 16px;

      div {
        &:nth-child(1) {
          margin-right: 25px;
        }
      }

      a {
        width: 152px;

        img {
          border-radius: 10px;
        }
      }
    }
  }

  &-submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 136px;
  }

  &-submit-button {
    max-width: 360px;
    width: 100%;
    align-self: center;
    padding: 12px 16px;
    border-radius: 12px;
    background: linear-gradient(270deg, #EB75B1 0%, #D95ACE 100%);
    color: $white;
    font-size: 16px;
    letter-spacing: -0.37px;
    line-height: 19px;
    text-align: center;
  }
}
