@import '../../../../assets/styles/colors';

.staking-currency-selector {
  &-currency {
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
    margin-right: 6px;
    font-weight: 600;
    color: $primary;
    &-disabled {
      color: $greyThird;
    }
  }

  &-arrow {
    color: $primary;
    &-disabled {
      color: $greyThird;
    }
  }
}
