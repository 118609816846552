.collapsed-block {
  flex: 1;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 14px;

    p {
      font-family: 'Futura PT', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      color: #161616;
    }
    .MuiButtonBase-root {
      background: #eef0f4;
      width: 28px;
      height: 28px;
      border-radius: 14px;
    }
  }
}
