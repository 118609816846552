/**
 *
 * Basic Formatting
 *
 **/

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

[hidden] {
  display: none !important;
}

/**
 * Body
 */

body {
  margin: 0;
  font-weight: 400;
  text-align: left;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100% !important;
  font-family: 'Futura PT', sans-serif;
}

body.admin {
  background: #f8f8f8;
}

body.no-scrollable {
  overflow: hidden;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: #1e2c4f;
}

h1 {
  font-size: 2em;
  line-height: initial;
}

h2 {
  font-size: 1.5em;
  line-height: initial;
}

h3 {
  font-size: 1.17em;
  line-height: initial;
}

h4 {
  font-size: 1em;
  line-height: initial;
}

h5 {
  font-size: 0.83em;
  line-height: initial;
}

h6 {
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  line-height: initial;
}

/**
 * General Text Formattings
 */

b,
strong {
  font-weight: 700;
}

i,
em,
dfn {
  font-style: italic;
}

address {
  font-style: normal;
}

abbr {
  font-variant: small-caps;
  letter-spacing: 1px;
  cursor: help;
}

sub,
sup {
  position: relative;
  font-size: 10px;
  line-height: 10px;
}

sub {
  top: 4px;
}

sup {
  top: -6px;
}

q,
cite,
blockquote {
  font-style: italic;
}

hr {
  height: 1px;
  margin: 0;
  border: 0;
  padding: 0;
}

p {
  display: block;
  margin-left: 0;
  margin-right: 0;
  line-height: 1.5em;
}

a:active {
  color: inherit;
}

.hidden {
  display: none !important;
}

.rotate0 {
  transition: 0.5s;
  transform: rotate(0deg) !important;
}
.rotate90 {
  transition: 0.5s;
  transform: rotate(90deg) !important;
}
.rotate180 {
  transition: 0.5s;
  transform: rotate(180deg) !important;
}
.rotate270 {
  transition: 0.5s;
  transform: rotate(270deg) !important;
}

.purple-button {
  background: #cf46b9 !important;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  height: 56px;
  align-items: center;
  justify-content: center;
  transition: ease 0.5s;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    color: #ffffff;
  }

  &:hover {
    transition: ease 0.5s;
    background: #b43ba1 !important;
  }
}

.white-button {
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
    color: #000000;
  }

  &:hover {
    background: white !important;
  }
}

.disabled-button {
  background: #e8b0df !important;
}

p,
span,
.MuiTypography-body1 {
  font-family: 'Futura PT', sans-serif !important;
}
