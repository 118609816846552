@import '../../../../assets/styles/colors';

.spot-account-schedule {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  @media all and (max-width: 1100px) {
    margin-top: 60px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -38px;
    width: 100%;
    &-title {
      font-size: 24px;
    }
  }
  &-redeem {
    margin-left: auto;
    &-button {
      background-color: transparent !important;
      border: 1px solid $primary !important;
      box-shadow: none !important;
      padding-right: 24px !important;
      padding-left: 24px !important;
      &-label {
        color: $primary !important;
        text-transform: none !important;
        font-size: 16px !important;
      }
    }
  }
  &-list {
    overflow-y: auto;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0px;
    width: 100%;
    @media all and (max-width: 1100px) {
      height: 500px;
      flex-grow: initial;
      flex-basis: initial;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $greyBackground;
    }

    &::-webkit-scrollbar-thumb {
      background: $greyFirst;
    }
    &-empty-indicator {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & > span {
        font-size: 20px;
      }
    }
    &-item {
      &:not(:first-child) {
        padding-top: 20px;
      }
      display: grid;
      grid-template-columns: 17.5rem 1fr;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $greyThirteen;
      &-investment-label {
        margin-bottom: 14px;
        display: inline-block;
      }
      &-label {
        color: $greyFifteen;
        font-size: 1rem;
      }
      &-value {
        display: inline-block;
        font-size: 16px;
      }
      &-left {
        flex: 1.8;
        padding-right: 30px;
        margin-right: 20px;
        border-right: 1px solid $greyThirteen;
      }
      &-right {
        display: flex;
        align-items: center;
        flex: 3.6;
        &-item {
          &-label {
            margin-right: 20px;
            display: inline-block;
            width: 97px;
          }
          &-value {
            display: inline-block;
            white-space: nowrap;
            &-indicator {
              margin: 0 6px;
            }
          }
          &-growth {
            color: $green;
          }
          &-decline {
            color: $red;
          }
        }
        &-info-item {
          display: flex;
          align-items: center;
          padding: 7px 0;
          &-value-container {
            display: flex;
            align-items: center;
            flex: 1;
            margin-right: 8px;
          }
        }
      }
    }
  }
  &-redeemable-date {
    margin-right: 10px;
  }
}
