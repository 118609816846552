@import '../../assets/styles/colors';

.staking-item {
  padding: 0;
  position: relative;
  &-content {
    padding: 16px;
  }
  &-main-text {
    color: $text;
    font-size: 14px;
    line-height: 16px;
  }
  &-value {
    color: $green;
    margin-bottom: 6px;
  }
  &-token-name {
    margin-left: 12px;
    color: $greySecond;
  }
  &-row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &-amount-field {
    margin-bottom: 12px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  &-buttons {
    margin-top: 24px;
    margin-bottom: 28px;
  }
  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 4px 16px;
    background-color: $primaryLight;
  }
  &-footer-label {
    color: $white;
  }
  &-period-end {
    display: block;
    color: $greySixth;
    text-align: right;
  }
  &-bottom-button-margin {
    margin-right: 20px;
  }
}
