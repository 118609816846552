@import '../../../../assets/styles/colors';

.tx-item {
  box-shadow: none !important;
  &-main-text {
    font-size: 20px;
    line-height: 24px;
    color: $text;
  }

  &-participant-detail {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    margin-left: 12px;
  }

  &-row {
    display: flex;
    align-items: center;
  }

  &-icon {
    margin-right: 12px;
  }

  &-header {
    justify-content: space-between;
    margin-bottom: 18px;
  }

  &-date {
    font-size: 16px;
    line-height: 19px;
    color: $greyFirst;
  }

  &-contract-label {
    margin-left: 12px;
  }

  &-button-wrapper {
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    border-bottom: 1px solid $greyThirteen;
  }

  &-value {
    text-align: left;
    font-size: 16px;
    &-highlight {
      font-weight: 600;
    }
  }
}
