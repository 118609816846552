@import '../../assets/styles/colors';

.custom-wc-qr-modal {
  align-items: center;

  &-wallet-icon {
    width: 35px;
    height: 35px;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;
      background: $primary;
      border-radius: 32px;
      margin-bottom: 22px;
    }

    path {
      fill: $white;
    }
  }

  &-title {
    font-size: 32px;
    line-height: 41px;
    color: $greyNinth;
  }

  &-info {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $greyTen;
    margin-bottom: 44px;
    width: 400px;
  }

  &-error-content {
    align-items: center;
  }
}

.flex-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
}
