@import '../../../../assets/styles/colors';

.dashboard-total-balance {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid $greyBorder;
  border-radius: 12px;
  &-value {
    font-size: 40px;
    line-height: 52px;
    color: $text;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
  }
  &-label {
    display: block;
    font-size: 20px;
    line-height: 26px;
    color: $greyText;
    margin-top: 20px;
    text-align: center;
  }
}
