@import '../../assets/styles/colors';

.settings {
  @media all and (max-width: 900px) {
    padding-top: 44px;
  }
}

.setting-blocks {
  display: grid;
  margin-top: 50px;
  column-gap: 32px;
  grid-template-columns: 50% 50%;
  padding-left: 176px;
  padding-right: 176px;
  & > * {
    margin-bottom: 32px;
  }
  @media all and (max-width: 1200px) {
    grid-template-columns: 100%;
  }
  @media all and (max-width: 1000px) {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.settings-block-community-icon {
  position: relative;
  display: flex;
  align-items: center;
  &-twitter {
    position: absolute;
    left: 18px;
    z-index: 1;
  }
  &-telegram {
    position: relative;
    z-index: 2;
  }
}
