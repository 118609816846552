@import '../../assets/styles/colors';

.token-icon-rounded {
  display: flex;
  min-width: 32px;
  height: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;

  &-default-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: fuchsia;
  }

  &-default-icon {
    font-size: 22px;
    color: white;
  }

  &-epan {
    background-color: $primary;
  }

  &-raw {
    background-color: transparent;
  }
}
