@import '../../../../assets/styles/colors';

.account {
  &_content {
    display: flex;
    flex-basis: 0px;
    flex-grow: 1;
    flex-direction: column;
    margin-top: 1rem;
    overflow-y: auto;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $greyBackground;
    }

    &::-webkit-scrollbar-thumb {
      background: $greyFirst;
    }

    &_empty {
      align-items: center;
      justify-content: center;
    }
  }

  &_redeemable {
    margin-bottom: 0.5rem;
  }

  &_button {
    max-width: 5.875rem;
  }

  &_item {
    margin-bottom: 1rem;
  }

  &_investment {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
  }

  &_balance {
    margin-left: 0.375rem;
  }

  &_balance_wrapper {
    display: flex;
    align-items: center;
    margin-top: 2px;
    margin-bottom: 10px;
  }
}
