@import '../../../../assets/styles/colors';

.step-progress-bar {
  display: flex;
  margin-bottom: 24px;

  &-step {
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: 2px solid $greyFirst;

    &-margin {
      margin-right: 8px;
    }

    &-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: $greyFirst;
      margin-bottom: 8px;

      &-active {
        color: $secondary;
      }

      &-completed {
        color: $greyNinth
      }
    }

    &-active {
      border-bottom-color: $secondary;
    }

    &-completed {
      border-bottom-color: $greyNinth
    }
  }
}
