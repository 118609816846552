@import '../../../../assets/styles/colors';

.top-up-spot-account-settings {
  &-auto-button {
    padding: 10.5px 32px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid $primary !important;
    &-label {
      font-size: 16px !important;
      color: $primary;
      font-weight: 600;
    }
  }
  &-input-wrapper {
    margin-bottom: 24px;
  }
}
