@import '../../assets/styles/colors';

.segmented-control {
  &.MuiToggleButtonGroup-root {
    background: $input;
    padding: 6px;
    border-radius: 12px;
  }

  &-button {
    &.MuiToggleButton-root {
      display: flex;
      flex: 1;
      background-color: transparent;
      border-radius: 8px;
      padding: 4px 0;
      color: $greyFifth;
      font-family: 'Futura PT Book', sans-serif;
      font-size: 16px;
      text-transform: capitalize;

      &.Mui-selected {
        background-color: $primary;
        color: $white;
      }
      &.Mui-selected:hover {
        background-color: $primary;
      }
    }

    &.MuiToggleButtonGroup-grouped {
      border-radius: 8px !important;
    }

    &.MuiToggleButtonGroup-grouped:hover {
      background: transparent;
    }
  }
}
