@import '../../../../assets/styles/colors';

.tx-history-info-modal {
  &-info-row {
    display: flex;
    align-items: baseline;
    padding-top: 18px;

    span:nth-of-type(2) {
      display: flex;
      flex: 1;
      margin: 0 6px;
      border-bottom: 2px dotted $greyFirst;
    }
  }

  &-section {
    &-title {
      color: #bdbdbd;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  &-main-section {
    padding-top: 35px;
  }

  &-secondary-section {
    padding: 35px 0 17px 0;
  }

  &-main-label {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 6px;
    color: $greyFirst;
  }

  &-main-value {
    color: $text;
    font-weight: 600;
  }

  &-description-value {
    width: 412px;
    margin-top: 8px;
  }

  &-clipboard-icon {
    width: 16px;
    margin-right: 6px;
    cursor: pointer;
  }

  &-scan-button {
    margin-top: 44px;
  }

  &-divider {
    &.MuiDivider-root {
      margin: 20px 0;
    }
  }

  &-description-wrapper {
    display: flex;
    flex-direction: column;
  }
}
