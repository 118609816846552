@import '../../assets/styles/colors';

.staking-table {
  &-main-text {
    color: $greyFirst;
    font-size: 16px;
    line-height: 19px;
  }
  &-divider {
    &.MuiDivider-root {
      margin-bottom: 12px;
    }
  }
  &-row-centered {
    display: flex;
    align-items: center;
  }
  &-wallet-name {
    font-size: 12px;
    line-height: 14px;
    color: $text;
    margin-left: 8px;
  }
  &-item-margin {
    margin-bottom: 20px;
  }
  &-item {
    border: 1px solid $greyFourth;
  }
}
