@import '../../assets/styles/colors';

.tx-history {
  max-width: 570px;
  margin: 96px auto 0 auto;

  &-loader {
    min-height: 200px;
  }

  &-scan-btn {
    margin-top: 21px;
  }

  &-title {
    font-size: 24px;
    line-height: 26px;
    color: $text;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &-item-margin {
    margin-bottom: 12px;
  }

  &-search-icon {
    color: $inputLabel;
    margin: 0px -8px 0px 16px;
  }

  &-items-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
  }

  &-search-input {
    margin-top: 20px;
  }
}
