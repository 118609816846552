.staking-earning {
  &-row {
    display: flex;
    align-items: center;
  }
  &-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 24px;
  }
  &-token-name {
    font-size: 20px;
    line-height: 26px;
    margin-left: 14px;
  }
  &-main-text {
    font-size: 14px;
    line-height: 18px;
  }
  &-label {
    opacity: 0.5;
  }
  &-amount-field {
    justify-content: space-between;
  }
}
