@import '../../assets/styles/colors';

.otp-input {
  &-root {
    &.otp-input-root {
      input {
        width: 100% !important;
        padding: 20px 0px;
        font-size: 20px;
        border-width: 1px;
        border-radius: 12px;
        border-color: $greyBorder;
      }
      input:focus-visible {
        border-color: transparent;
        outline: 1px solid $primary;
      }
    }
  }
  &-spacer {
    width: 25px;
  }
}
