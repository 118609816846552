@mixin flexSize($value) {
  flex: $value;
}
$stakingHeight: 260px;

.dashboard {
  &-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    @media all and (max-width: 1049px) {
      flex-direction: column;
    }
  }
  &-balances {
    margin-top: 40px;
  }
  &-section {
    margin-bottom: 36px;
    @media all and (max-width: 1049px) {
      margin-bottom: 12px;
    }
  }
  &-spacer {
    width: 10px;
    @media all and (max-width: 1049px) {
      width: 0;
    }
  }
  &-balance-item {
    min-height: 342px;
  }
  &-bank-account-spacer {
    height: 12px;
  }
  &-item-wrapper {
    display: flex;
    flex: 1;
    @media all and (max-width: 1049px) {
      flex: auto;
      margin-bottom: 12px;
    }
  }
  &-one-third-wrapper {
    @include flexSize(1/3);
  }
  &-two-third-wrapper {
    @include flexSize(2/3);
  }
  &-collapsed-content {
    display: flex;
    height: $stakingHeight;
  }
  &-collapsed-staking-content {
    @media all and (max-width: 1049px) {
      height: $stakingHeight * 2;
    }
  }
  &-bank-accounts-wrapper {
    flex-direction: column;
    @media all and (max-width: 1200px) {
      margin-bottom: 12px;
    }
  }
  &-staking-content {
    display: flex;
    flex: 1;
    @media all and (max-width: 1200px) {
      flex-direction: column;
    }
  }
  &-token-balances {
    position: absolute;
    top: -30px;
    display: flex;
    font-size: 22px;
    line-height: 26px;
  }
  &-token-balances-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    @media all and (max-width: 1049px) {
      flex-direction: column;
      margin-top: 30px;
    }
  }

  &-graph {
    overflow: hidden;
  }
}
