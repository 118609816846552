@import '../../assets/styles/colors';

.dashboard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;

  @media all and (max-width: 1200px) {
    flex-direction: column;
  }

  &-address-button {
    padding: 0;
    border-width: 0;
    background-color: transparent;
    :hover {
      cursor: pointer;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-greetings {
    font-style: normal;
    font-family: 'Futura PT', sans-serif !important;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    width: 400px;
    color: #161616;
    margin-right: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
    @media all and (max-width: 1200px) {
      margin-bottom: 12px;
      text-align: center;
    }
  }

  &-user {
    width: 100%;
    max-width: 536px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 15px;

    &-profile {
      width: 30px;
      height: 30px;
      background-color: gray;
      border-radius: 100%;
    }

    &-wallet-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        height: 16px;
        width: 16px;
        margin-right: 12px;
      }
    }
  }

  &-wallet-address {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: $text;
  }

  &-wallet-name {
    font-size: 14px;
    line-height: 20px;
    color: $greySecond;
  }
}
