@import '../../../../assets/styles/colors';

.edit-profile {
  &-webcam {
    margin-top: 4px;
    width: 100%;
    height: 220px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-crop {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 180px;
      height: 180px;
      background-color: transparent;
      border: 2px dashed white;
      border-radius: 200px;
      z-index: 90;
      box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
    }
    &-result {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      & > img {
        border-radius: 200px;
      }
      &-remove {
        background-color: $greyNinth;
        opacity: 0.7;
        width: 52px;
        height: 52px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    &-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 44px;
      &-outlined {
        background-color: transparent !important;
        color: $primary !important;
        box-shadow: none !important;
        border: 1px solid $primary !important;
      }
      & > * {
        flex: 1;
        &:first-child {
          padding-right: 16px;
          background-color: transparent;
        }
        &:last-child {
          padding-left: 16px;
        }
      }
      &-label {
        font-size: 16px;
        &-outlined {
          color: $primary !important;
        }
      }
    }
  }
}
