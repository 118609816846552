@import '../../assets/styles/colors';

.top-up-spot-account {
  max-width: 520px;
  margin: auto;
  &-discount {
    &-apply-text {
      color: $primary;
      text-transform: uppercase;
      cursor: pointer;
    }
    &-text {
      color: $green;
      margin-top: 10px;
      display: inline-block;
    }
  }
  &-lock-text {
    color: $green;
    margin-top: 10px;
    display: block;
  }
  &-account-settings {
    display: flex;
    align-items: center;
    margin-top: 28px;
    cursor: pointer;
    &-wrapper {
      display: flex;
    }
    & > svg {
      margin-right: 6px;
    }
    & > span {
      font-size: 16px;
      color: $primary;
    }
  }
  &-back {
    margin-top: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 51px;
    & path {
      stroke: $primary !important;
    }
    &-text {
      font-size: 20px;
      color: $primary;
      margin-left: 18px;
      display: inline-block;
    }
  }
  &-title {
    font-size: 22px;
    font-weight: 500;
    display: block;
    margin-bottom: 10px;
  }
  &-description {
    color: $greyTen;
    display: inline-block;
    margin-bottom: 12px;
  }
  &-input {
    &-wrapper {
      flex: 1;
      margin-right: 12px;
    }
    &-label {
      margin-bottom: 4px;
      display: inline-block;
    }
    &-container {
      display: flex;
      align-items: flex-end;
      width: 100%;
    }
  }
  &-use-max {
    padding: 10.5px 19px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border: 1px solid $primary !important;
    &-label {
      font-size: 16px !important;
      color: $primary;
    }
  }
  &-quote {
    margin-top: 8px;
    margin-bottom: 14px;
    &-label {
      color: $greyTen;
    }
    &-error {
      margin-top: 20px;
    }
  }
  &-exchange-indicator {
    display: flex;
    align-items: center;
    &-line {
      flex: 1;
      height: 1px;
      background-color: $greyThirteen;
    }
    &-icon {
      margin: 0 24px;
    }
  }
  &-action {
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 52px;
      &-spread {
        justify-content: space-between;
      }
    }
    &-button {
      padding: 12px 32px !important;
      &-cancel {
        background-color: transparent !important;
        border: 1px solid $primary !important;
        box-shadow: none !important;
        &-container {
          margin-right: 24px;
        }
        &-label {
          color: $primary;
        }
      }
      &-label {
        font-size: 18px !important;
      }
    }
  }
  &-amount-input-wrapper {
    margin-bottom: 20px;
  }
  &-pay-with {
    &-currency {
      font-weight: 450 !important;
      color: $primary !important;
    }
    &-arrow {
      path {
        stroke: $primary;
      }
    }
  }
  &-apply {
    cursor: pointer;
    text-transform: uppercase;
    color: $greySecond;

    &-active {
      color: $primary
    }
  }
}
