@import '../assets/styles/colors';

.userAuth {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  &-image {
    width: 50%;
    background-color: #f2ab3c;
    background-image: url('../assets/images/bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 30%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 1049px) {
      display: none;
    }
  }

  &-form {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media all and (max-width: 1049px) {
      width: 100%;
    }
  }

  &-head {
    padding-bottom: 20px;

    &-title {
      font-style: normal;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      color: #000000;
    }

    &-subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #9f9fb6;
    }
  }
}

.page-wrapper {
  display: flex;
  flex: 1;
  padding: 44px 0px;
  flex-direction: column;
  background-color: $pageBackground;
  &-content {
    width: 1032px;
    margin: auto;
    @media all and (max-width: 1440px) {
      width: 90%;
    }
    @media all and (max-width: 900px) {
      width: 70%;
    }
    @media all and (max-width: 600px) {
      width: 90%;
    }
  }
}
