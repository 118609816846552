@import '../../assets/styles/colors';

.common-tile-container {
  display: flex;
  flex: 1;
  border-radius: 12px;
  padding: 1rem 1.25rem;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 16px $tileShadow;
}
