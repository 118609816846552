@import '../../assets/styles/colors';

.resetPassword {
  width: 100%;
  max-width: 460px;
  @media all and (max-width: 1049px) {
    padding: 0px 24px;
  }
  &-main-text {
    font-size: 14px;
    color: $text;
  }
  &-input {
    margin-bottom: 40px;
  }
  &-input-title {
    display: block;
    padding-bottom: 6px;
  }
  &-link {
    display: block;
    text-align: center;
    margin-top: 32px;
    font-size: 20px;
    line-height: 26px;
    color: $primary;
  }
  &-success-description {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: $greyFirst;
    text-align: center;
    margin-bottom: 40px;
  }
  &-submit-button-container {
    display: flex;
    justify-content: center;
  }

  &-submit-button {
    &.MuiButton-root {
      width: auto;
    }
  }
}
