@import '../../../../assets/styles/colors';

.privacy {
  &-description-text {
    color: $greyTen;
    font-size: 16px;
  }
  &-circle-list {
    list-style-type: circle;
    padding-left: 40px !important;
    & > li{
      margin: 10px 0;
    }
  }
  &-alpha-list {
    list-style-type: lower-alpha;
    padding-left: 40px !important;
    & > li{
      margin: 10px 0;
    }
  }
  &-block {
    margin: 32px 0;
    &:last-child {
      margin: 32px 0 0 0;
    }
    &-title {
      font-size: 22px;
    }
    &-description {
      margin-top: 12px;
      & > span {
        color: $greyTen;
      }
    }
  }
}
