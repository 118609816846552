@import '../../../../assets/styles/colors';

.edit-profile {
  &-invisible-input {
    display: none;
  }
  &-input-container {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &-input {
    & input {
      padding: 12px 16px !important;
    }
    &-label {
      color: $greyTen;
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 14px;
      display: inline-block;
    }
  }
  &-phone-input {
    & input {
      font-size: 15px !important;
      font-weight: 500;
    }
  }
  &-action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 24px;
  }
  &-action-button {
    font-size: 16px !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    width: 98px !important;
    &-label {
      font-size: 14px !important;
    }
    &-cancel {
      background-color: transparent !important;
      box-shadow: none !important;
      border: 1px solid $primary !important;
      margin-right: 12px !important;
      &-label {
        color: $primary !important;
      }
    }
  }
  &-form {
    padding-top: 8px;
    &-photo {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-upload-options {
        z-index: 999;
        position: absolute;
        background-color: white;
        border-radius: 12px;
        top: calc(100% + 12px);
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12), 0px 32px 44px -16px rgba(0, 0, 0, 0.1);
        width: 100%;
        padding: 16px;
        display: none;
        &-active {
          display: initial;
        }
        &-item {
          cursor: pointer;
          &:first-child {
            padding-bottom: 12px;
            border-bottom: 1px solid $greyEleventh;
          }
          &:last-child {
            padding-top: 12px;
          }
          &-only {
            border-bottom: none !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }
      }
      &-upload-container {
        position: relative;
      }
      &-img {
        width: 120px !important;
        height: 120px !important;
        font-size: 40px !important;
        margin-bottom: 17px;
      }
      &-upload {
        display: flex;
        align-items: center;
        padding: 10px 21.5px !important;
        &-text {
          color: white;
          text-transform: initial;
          margin-left: 10px;
          font-size: 16px;
        }
        &-img-rotated {
          transform: rotate(180deg);
        }
      }
      &-remove {
        margin-top: 20px;
        margin-bottom: 24px;
        cursor: pointer;
        & > span {
          color: $primary;
          font-size: 16px;
        }
      }
    }
  }
}
