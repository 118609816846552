@import '../../../../assets/styles/colors';

.disconnected-wallet {
  &-title {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 16px;
    color: $text;
    font-weight: 600;
  }

  &-info {
    font-size: 16px;
    line-height: 20px;
    color: $greyThird;
  }

  &-button {
    width: 158px;
    align-self: flex-end;
    margin-top: 32px;
  }
}
