@import '../../assets/styles/colors';

.signUp {
  width: 100%;
  max-width: 460px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  align-items: center;

  @media all and (max-width: 1049px) {
    padding: 42px 24px 0 24px;
  }

  &-form {
    width: 100%;
  }

  &-head {
    &-info {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #9f9fb6;
    }
  }

  &-codeInput {
    margin: 38px auto 40px;

    &-form {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &-item {
        width: 96px !important;
        height: 65px;
        border: 1px solid #8f8f8f;
        border-radius: 12px;

        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
        color: #111111;
      }
    }
  }
}

.top-navigation {
  display: flex;
  width: 100%;
  margin-bottom: 76px;
  justify-content: space-between;

  button {
    outline: none;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $primary;
    }
  }

  &-arrow-left {
    margin-right: 10px;

    path {
      stroke: $primary
    }
  }

  &-cancel-registration {
    font-weight: 400 !important;
    color: $greyTen !important;
  }
}
