@import '../../../../assets/styles/colors';

.spot-account-redeem-modal {
  border-top: 1px solid $greyThirteen;
  padding-top: 24px;
  &-clipboard-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    & > span {
      display: inline-block;
      margin-right: 10px;
      font-size: 16px;
    }
  }
  &-label {
    color: $greyTen;
  }
  &-value {
    color: $greyNinth;
    font-weight: 500;
  }
  &-value-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;
  }
  &-insufficient-epan {
    &-container {
      display: flex;
      align-items: center;
      &-img {
        margin-right: 10px;
      }
    }
  }
  &-close-button {
    background-color: $primary !important;
    &-container {
      margin-right: 0 !important;
    }
  }
}
