@import '../../../../assets/styles/colors';

.bank-account {
  justify-content: center;
  &-row {
    display: flex;
    align-items: center;
  }
  &-header {
    margin-bottom: 16px;
  }
  &-row-space {
    justify-content: space-between;
  }
  &-bank-name {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  &-title {
    font-size: 16px;
    line-height: 26px;
  }
  &-main-text {
    font-size: 14px;
    opacity: 0.5;
  }
  &-icon-rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: $bankAccountRounded;
  }
  &-empty-icon-rounded {
    background-color: $greyBackground;
  }
  &-balance {
    font-size: 20px;
    line-height: 26px;
  }
  &-bullet {
    font-size: 20px;
    margin-right: 8px;
  }
  &-account-number {
    display: flex;
    align-items: center;
  }
  &-bullet-wrapper {
    height: 18px;
  }
  &-disconnected {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
  }
  &-disconnected-title {
    font-size: 16px;
    margin-bottom: 2px;
    opacity: 0.7;
  }
}
