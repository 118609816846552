@import '../../assets/styles/colors';

.checkbox-input {
  &-root {
    &:hover {
      background-color: transparent !important;
    }
  }

  &-icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #f6f6f9;
  }

  .MuiCheckbox-colorPrimary.Mui-checked:hover {
    background-color: transparent !important;
  }

  &-label {
    padding-left: 7px;
    &.MuiTypography-body1 {
      font-size: 14px;
      color: $text;
    }
  }

  &-checkedIcon {
    width: 20px !important;
    height: 20px !important;
    color: white;
    border-radius: 4px;
    background-color: #cf46b9;
  }

  &-error {
    margin: 0 14px;
  }
}
