@import '../../../../assets/styles/colors';

.spot-account-details {
  border: 1px solid $greyThirteen;
  border-radius: 12px;
  padding: 40px 20px;
  margin-bottom: 24px;
  &-singular {
    margin-bottom: 0;
  }
  &-icon {
    width: 52px;
    height: 52px;
    border-radius: 100px;
    &-container {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;
    }
    & > img {
      width: 100%;
    }
  }
  &-balance {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      color: $greyTen;
      font-size: 16px;
    }
    &-value {
      color: $greyNinth;
      font-size: 34px;
      font-weight: bold;
      margin: 10px 0;
    }
    &-fiat-value {
      color: $greyFourteen;
      font-size: 16px;
    }
  }
  &-top-up {
    margin: 30px 52.5px 0 52.5px;
    &-label {
      color: white;
      font-size: 16px !important;
      white-space: nowrap;
    }
    margin-bottom: 32px;
  }
  &-available-to-redeem {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    &-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 12px;
      &-title {
        color: $greyTen;
        font-size: 16px;
        margin-bottom: 4px;
      }
      &-value {
        font-size: 18px;
      }
    }
  }
  &-nearest-redeem-date {
    display: flex;
    align-items: center;
    justify-content: center;
    &-text {
      color: $greyTen;
      margin-left: 12px;
    }
    &-value {
      color: black;
    }
  }
}
