.ccb-confirm-modal {
  &-button {
    margin-top: 16px;
  }

  &-subtitle {
    width: auto;
    text-align: center;
  }
}
