@import '../../assets/styles/colors';

.signIn {
  width: 100%;
  max-width: 460px;
  @media all and (max-width: 1049px) {
    padding: 0px 24px;
  }
  &-password-input {
    margin-bottom: 10px;
  }
  &-email-input {
    margin-bottom: 40px;
  }
  &-remember-me {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 8px;
    margin-bottom: 40px;
  }
  &-main-text {
    font-size: 14px;
    color: $text;
  }
  &-sign-up {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  &-link {
    color: $primary;
  }
  &-have-account-question {
    margin-right: 5px;
  }
  &-input-title {
    display: block;
    padding-bottom: 6px;
    color: $greySecond;
  }

  &-noAccount {
    width: 100%;
    margin: 32px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;

      span {
        color: #cf46b9;
      }
    }
  }

  &-auth-header {
    margin-bottom: 32px;
  }

  &-submit-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 136px;
  }

  &-submit-button {
    &.MuiButton-root {
      width: auto;
    }
  }
}
