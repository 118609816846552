@import '../../assets/styles/colors';

.notFound {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &-image {
    width: 100%;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      margin: 82px 0 20px;
      font-family: 'Futura PT', sans-serif !important;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 26px;
      color: $text;
    }

    &-subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      color: $greyThird;
      margin-bottom: 20px;
    }
  }
}
