@import '../../assets/styles/colors';

.common-input {
  &.MuiInput-root {
    background-color: $input;
    border-radius: 8px;
    border-bottom-color: transparent;

    input {
      padding: 12px 16px;
      border-radius: 8px;
    }

    &.MuiInput-root::before {
      border-bottom-width: 0;
    }

    &.MuiInput-root::after {
      border-bottom-width: 0;
    }

    &.MuiInput-root:hover::before {
      border-bottom-width: 0;
    }

    textarea {
      padding: 12px 16px;
    }
  }
}
