@import '../../assets/styles/colors';

.redeem-spot-account {
  max-width: 520px;
  margin: auto;
  &-back {
    margin-top: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 51px;
    & path {
      stroke: $primary !important;
    }
    &-text {
      font-size: 20px;
      color: $primary;
      margin-left: 18px;
      display: inline-block;
    }
  }
  &-not-connected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &-info {
      display: flex;
      align-items: center;
      &-text {
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
  &-title {
    font-size: 22px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }
  &-description {
    color: $greyTen;
    display: inline-block;
    margin-bottom: 12px;
  }
  &-wallet {
    &-connected {
      margin-bottom: 24px;
      &-indicator {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }
      &-widget {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        &-padding {
          padding-bottom: 18px !important;
        }
        &-icon-container {
          background-color: white;
          width: 42px;
          height: 42px;
          box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
        }
        &-wallet-info {
          display: flex;
          &-details {
            margin-left: 14px;
            &-balance {
              font-size: 18px;
              font-weight: 500;
            }
            &-change-wallet {
              color: $primary;
              cursor: pointer;
            }
            &-change-wallet-disabled {
              cursor: default;
            }
            &-data {
              margin-bottom: 25px;
              display: flex;
              flex-direction: column;
              &-type {
                font-size: 18px;
                color: $greyNinth;
                display: inline-block;
                margin-bottom: 4px;
              }
              &-address {
                color: $greyTen;
                font-weight: 300;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    &-external {
      &-widget {
        &-head {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          &-text {
            margin-left: 14px;
          }
        }
      }
    }
    &-title {
      color: $greyTen;
      font-size: 18px;
      display: inline-block;
      margin-left: 5px;
    }
    &-widget {
      border: 1px solid $greyThirteen;
      border-radius: 12px;
      padding: 12px;
      background-color: white;
      transition-duration: 0.2s;
      &-focused {
        background-color: $input;
      }
    }
  }
  &-action {
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 52px;
      &-spread {
        justify-content: space-between;
      }
    }
    &-button {
      padding: 12px 32px !important;
      &-cancel {
        background-color: transparent !important;
        border: 1px solid $primary !important;
        box-shadow: none !important;
        &-container {
          margin-right: 24px;
        }
        &-label {
          color: $primary;
        }
      }
      &-label {
        font-size: 18px !important;
      }
    }
  }
}
