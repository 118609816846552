@import '../../assets/styles/colors';

.userAuth {
  &-head {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    align-items: center;
    margin-bottom: 32px;
    border-bottom: 1px solid $greyEight;

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      color: #000000;
    }

    &-subTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #9f9fb6;
      margin-top: 8px;
    }
  }
}
