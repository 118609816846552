@import '../../assets/styles/colors';

.connection-wallet-modal {
  &-item {
    &.MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 12px;
      padding: 12px 16px;
      border: 1px solid $greySeventh;

    }
    &-margin {
      &.MuiMenuItem-root {
        margin-bottom: 12px;
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-providers {
    margin-top: 24px;
  }

  &-icon-rounded {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: $white;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 2px $tileShadow;
  }

  &-label {
    font-weight: 600;
    color: $text;
    margin-left: 12px;
  }

  &-info {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
    color: $greySecond;
    margin-left: 42px;
  }

  &-connect-button {
    &.MuiButton-root {
      width: 158px;
    }
    &-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
  }
}
