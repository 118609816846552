@import '../../assets/styles/colors';

.hint {
  &-row {
    display: flex;
    align-items: center;
  }
  &-check {
    color: $green;
    margin-left: 5px;
  }
  &-main-text {
    font-size: 14px;
    line-height: 17px;
    color: $validationInfo;
  }
  &-row-value {
    margin-bottom: 8px;
  }
}
