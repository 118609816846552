.spot-account {
  margin-top: 96px;
  &-sections {
    display: flex;
    & > * {
      &:first-child {
        padding-right: 12px;
      }
      &:last-child {
        padding-left: 12px;
      }
    }
    @media all and (max-width: 1100px) {
      flex-direction: column;
      padding: 0 10%;
    }
    @media all and (max-width: 600px) {
      padding: 0 3%;
    }
  }
  &-section {
    display: flex;
    flex-direction: column;
    &-fill {
      flex: 1;
    }
    &-item {
      display: flex;
      flex-direction: column;
      &-overflown {
        flex-basis: 0;
        flex-grow: 1;
      }
    }
  }
}
