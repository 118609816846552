@import '../../assets/styles/colors';

.dashboard-header-user-wallet {
  &-button {
    background: #eef0f4;
    border-radius: 8px;
    height: 44px;
    min-width: 210px;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    padding: 6px 20px 6px 8px;
    align-items: center;

    &-current {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        margin-right: 10px;
      }

      span {
        font-size: 16px;
        line-height: 21px;
        color: $text;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    &-arrow {
      display: flex;
      margin-left: 20px;
    }
  }

  &-modal {
    &-item {
      margin: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-disconnect-button {
    &.MuiButton-root {
      background: $error;
    }
    &.MuiButton-root:hover {
      background: $error;
    }
  }

  &-disconnect-button-container {
    margin-right: 20px;
  }

  &-unsupported-label {
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: $text;
  }

  &-detail-label {
    font-size: 14px;
    line-height: 20px;
    color: $secondary;
    margin-left: 6px;
  }

  &-warning {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 20px;
    width: 20px;
    align-self: center;
    justify-content: center;
    border-radius: 10px;
    background-color: $blueone;
    span {
      color: $white;
    }
  }

  &-network-tooltip {
    max-width: 300px;
  }

  &-unsupported-network {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
}
