@import '../../../../assets/styles/colors';

.edit-profile {
  &-description {
    text-align: center;
    padding: 8px 39.5px 32px 39.5px;
    & > span {
      color: $greyTwelve;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  &-verify {
    &-otp-input-container {
      padding: 0 69.5px;
      @media all and(max-width: 900px){
        padding: 0 5%;
      }
    }
    &-resend-timer {
      margin-bottom: 44px;
      &-text{
        font-weight: 400 !important;
        font-size: 16px !important;
      }
    }
    &-action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > * {
        flex: 1;
        &:first-child {
          padding-right: 16px;
        }
        &:last-child {
          padding-left: 16px;
        }
      }
    }
    &-action-button {
      &-cancel {
        background-color: transparent !important;
        box-shadow: none !important;
        border: 1px solid $primary !important;
        &-label {
          color: $primary !important;
        }
      }
    }
  }
}
