@import '../../../../assets/styles/colors';

.settings-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &-first-part {
    display: flex;
  }
  &-second-part {
    display: flex;
    align-items: center;
    @media all and (max-width: 900px) {
      flex-direction: column;
      margin-left: 20px;
      align-items: flex-end;
    }
  }
  &-user {
    &-name {
      display: block;
      font-size: 32px;
      margin-bottom: 14px;
      @media all and (max-width: 900px) {
        font-size: 28px;
      }
    }
    &-email {
      display: block;
      font-size: 16px;
      color: $greyTen;
      margin-bottom: 14px;
    }
    &-avatar {
      margin-right: 14px;
    }
    &-button {
      border: 1px solid $primary !important;
      background-color: transparent !important;
      box-shadow: none !important;
      padding: 8px 34.5px !important;
      @media all and (max-width: 900px) {
        padding: 8px 14.5px !important;
        white-space: nowrap;
      }
      &-label {
        color: $primary !important;
        text-transform: initial;
        margin-left: 10px;
      }
    }
  }
  &-edit-profile {
    &-label {
      color: white;
      text-transform: initial;
      font-size: 16px;
    }
    &-button {
      padding: 8px 32px !important;
      margin-left: 20px;
      @media all and (max-width: 900px) {
        padding: 8px 15px !important;
      }
    }
    &-container {
      margin-left: 20px;
      @media all and (max-width: 900px) {
        margin-top: 10px;
      }
    }
  }
}
