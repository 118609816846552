.epanPrice {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  &-container {
    padding: 20px 8px 8px 20px;
  }

  &-item {
    margin-right: 12px;
    margin-bottom: 12px;
    &-header {
      margin-bottom: 10px;
      &-title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #65617c;
      }

      &-chip {
        margin-left: 10px;
        padding: 1px 3px;
        display: inline-flex;
        background: #e8f0fb;
        border-radius: 3px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        align-items: center;
        text-align: center;
        color: #65617c;
      }
    }
    &-value {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-amount {
        font-family: 'Futura PT', sans-serif !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #161616;
      }

      &-percentage {
        margin-left: 15px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;

        img {
          margin-right: 7px;
        }
        span {
          font-family: 'Futura PT', sans-serif !important;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 26px;
        }
      }
      .negative {
        color: #f33642;
      }
      .positive {
        color: #38c17c;
      }
    }
  }
}
