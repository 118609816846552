@import '../../assets/styles/colors';

.page-content-container {
  display: flex;
  flex: 1;
  border-radius: 16px;
  padding: 24px;
  margin: 0px auto;
  flex-direction: column;
  background-color: $white;
  box-shadow: 0px 0px 16px $tileShadow;
}
