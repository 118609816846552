@import '../../assets/styles/colors';

.amount-field {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &-main-text {
    font-size: 16px;
    line-height: 19px;
    color: $text;
    font-weight: 700;
  }
  &-label {
    font-weight: 500;
    color: $greyFirst;
  }
}
