@import '../../assets/styles/colors';

.base-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    &-head {
      display: flex;
      align-items: center;
    }
    &-centered {
      justify-content: center;
    }

    &-icon {
      margin-right: 12px;
    }
    &-title {
      font-size: 24px;
      line-height: 26px;
      font-weight: 600;
      color: $text;
    }

    &-close-button {
      display: flex;
      background-color: transparent;
      border: 0;
      padding: 0;
      :hover {
        cursor: pointer;
      }
    }
  }

  &-content {
    padding: 24px;
    border-radius: 12px;
    width: 520px;
    max-height: 98vh;
    overflow-y: auto;
    background: $white;
    margin: 0px 16px;
  }
}
