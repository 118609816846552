@import '../../../../assets/styles/colors';

.settings-item-block {
  box-shadow: 0px 6px 16px 0px #0000000a;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background-color: white;
  border-radius: 12px;
  padding: 32px 24px;
  z-index: 2;
  &-disabled {
    pointer-events: none;
  }
  &-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  &-text {
    color: black;
    font-size: 16px;
    transition-duration: 0.3s;
    &-disabled {
      color: $greyFirst;
    }
  }
  &-icon {
    width: 52px;
    height: 52px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    & path {
      transition-duration: 0.3s;
    }
    & circle {
      transition-duration: 0.3s;
    }
    &-disabled {
      & .color-changeable-path {
        fill: $greyFirst;
        stroke: initial !important;
      }
      & path {
        stroke: $greyFirst;
      }
    }
  }
  &:hover {
    .settings-item-block-text {
      color: $primary;
      font-size: 16px;
    }
    .settings-item-block-icon {
      & .color-changeable-path {
        fill: $primary;
        stroke: initial !important;
      }
      & path {
        stroke: $primary;
      }
    }
  }
}
