@import '../../../../assets/styles/colors';

.verification {
  &-next-button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  &-data {
    color: $primary
  }
}

.resend-timer {
  display: flex;
  justify-content: center;
  margin-top: 32px;

  button {
    outline: none;
    background: transparent;
    border: none;
    cursor: pointer;

    span {
      font-size: 20px;
      line-height: 26px;
      font-weight: 600;
      color: $primary
    }
  }
}
